import './Card.css';

const STARS_MAP = {
  0: 0,
  1: 1,
  2: 1,
  3: 1,
  4: 1,
  5: 1,
}

function Card({ card, correctMap }) {
  const initialCard = card.idx === 0;
  const correct = correctMap[card.card_id];
  const answerChecked = correctMap.hasOwnProperty(card.card_id);
  let className = initialCard ? 'Initial' : (answerChecked ? (correct ? 'Correct' : 'Incorrect') : '');
  if (!correctMap.hasOwnProperty(card.card_id)) {
    className += ' Draggable';
  }

  return (
      <div className={'Card ' + className}>
          <div className={'Heading'}>
            {card.idx > 0 &&
              <div>
                {[...Array(STARS_MAP[card.idx]).keys()].map((object, i) => <span className="material-symbols-rounded" key={i}>star</span>)}
              </div>
            }
          </div>
          <div className={'Image' + (card.needs_bg ? ' Backgrounded' : '')} style={{backgroundImage: `url("${card.image_url}")`}} title={card.image_attr ? "Image attribution: " + card.image_attr : undefined}>
          </div>
          <div className={'CardContent'}>
              <div className={'Text'}>
                  <div className={'Title'}>{card.title}</div>
                  <div className={'Description'}>{card.short_desc}</div>
              </div>
              <a className={'Date ' + (card.value ? 'Linked' : '')} href={card.value ? card.wikipedia_url : undefined} target={'_blank'}>
                {card.value_name}{(className === ' Draggable') && <span>?</span>}
                &nbsp;
                {card.value && card.value_type === 'datetime' && new Date(Number(card.value)).getUTCFullYear()}
                {card.value && card.value_type === 'count' && card.value}
                {card.value && card.wikipedia_url &&
                  <span className="material-symbols-rounded" style={{fontSize: '14px', paddingLeft: '4px'}}>
                    open_in_new
                  </span>
                }
              </a>
          </div>
      </div>
  );
}

export default Card;
export { STARS_MAP };
