import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Card from "./Card";
import './App.css';
import GameEnd from "./GameEnd";

const grid = 8;
const items = 6;

const getItemStyle = (isDragging, draggableStyle, fromSensor, items) => {
    const widthTaken = items * 208;
    const widthRemaining = Math.max(window.innerWidth - widthTaken, 0) / 2;
    if (!fromSensor
        && draggableStyle.transform !== null && draggableStyle.transition !== null
        && draggableStyle.transition.indexOf('transform') !== -1) {
        // animating drop -- offset by 104px to fix "centering"
        const match = [...draggableStyle.transform.matchAll(/(translate\()(.+)(px,.+\))/g)][0];
        draggableStyle.transform = `${match[1]}${Number(match[2]) - Math.min(104, widthRemaining)}${match[3]}`;
    } else if (!fromSensor && draggableStyle.transform !== null) {
        // combined with hack in index.js to fix centering offset
        const match = [...draggableStyle.transform.matchAll(/(translate\()(.+)(px,.+\))/g)][0];
        draggableStyle.transform = `${match[1]}${Number(match[2]) - Math.min(104, widthRemaining)}${match[3]}`;
    }
    return ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        margin: "8px",
        transformOrigin: "top right",

        // change background colour if dragging
        //border: isDragging ? "5px solid lightgreen" : "none",

        // styles we need to apply on draggables
        ...draggableStyle
    });
}

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "rgba(32,32,32,1)" : "rgba(26,26,26,1)",
    transition: "background 0.2s",
    borderRadius: "8px",
    padding: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "auto",
    position: "relative",
});

const getLongListStyle = isDraggingOver => {
    let base = getListStyle(isDraggingOver);
    base.background = "rgba(0, 0, 0, 0)";
    base.flexGrow = "1";
    base.maxHeight = "unset";
    base.width = window.isScrollScreen() ? "1248px" : "unset";
    return base;
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            name: '',
            items: [],
            items2: [],
            correctMap: {},
            helpShown: Boolean((localStorage.getItem('helpShown') || 'false') === 'true'),
        };
        this.fetchDeck();
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        if (!this.state.helpShown) {
            console.log('First visit, showing help dialog.');
            this.openModal(document.querySelector('#help-dialog'));
            localStorage.setItem('helpShown', 'true');
        }
    }

    fetchDeck() {
        console.log('Fetching deck...');
        // Can add ?id= to fetch a specific ID
        fetch('https://trivi-api.onrender.com/deck')
            .then((response) => response.json())
            .then((data) => {
                let correctMap = {};
                correctMap[data.cards[0].card_id] = true;
                this.setState({
                    id: data.id,
                    items: [data.cards[0]],
                    items2: data.cards.slice(1),
                    name: data.name,
                    timeNext: data.timeNext,
                    valueType: data.valueType,
                    correctMap,
                });
                // preload images
                for (let item of data.cards) {
                    if (item.image_url) {
                        new Image().src = item.image_url;
                    }
                }
            });
    }

    getNextCard() {
        return this.state.items2[0];
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination || result.destination.droppableId === 'droppable2'
          || result.source.droppableId !== 'droppable2') {
            return;
        }

        const items = this.state.items;
        items.splice(result.destination.index, 0, this.state.items2[0]);

        const items2 = this.state.items2;
        items2.shift();

        this.setState({
            items, items2
        });

        const deckStr = items.map(item => item.card_id).join(',');

        console.log('Fetching result...');

        fetch(`https://trivi-api.onrender.com/verify?timeline=${deckStr}&id=${this.state.id}`)
          .then((response) => response.json())
          .then((data) => {
              const offset = data.cards.map(item => item.card_id).indexOf(result.draggableId) - items.map(item => item.card_id).indexOf(result.draggableId);
              const needsDrag = offset !== 0;
              const preDrag = this.api.tryGetLock(result.draggableId);
              const snapLift = preDrag.snapLift();
              if (needsDrag) {
                  for (let i = 0; i < Math.abs(offset); i++) {
                      if (offset > 0) {
                          snapLift.moveRight();
                      } else {
                          snapLift.moveLeft();
                      }
                  }
              }
              setTimeout(() => {
                  snapLift.drop();
                  let correctMap = this.state.correctMap;
                  correctMap[result.draggableId] = offset === 0;
                  this.setState({
                      items: data.cards,
                      correctMap,
                  });

                  console.log('Ready for next lift.');
              }, needsDrag ? 200 : 0);
          });
    }

    captureSensorApi(appSelf) {
        return (api) => {
            appSelf.api = api;
        }
    }

    openModal(elem) {
        elem.inert = true;
        elem.showModal();
        elem.inert = false;
        elem.addEventListener('click', (e) => {
            const rect = e.target.getBoundingClientRect();
            const minX = rect.left + e.target.clientLeft;
            const minY = rect.top + e.target.clientTop;
            if ((e.clientX < minX || e.clientX >= minX + e.target.clientWidth) ||
                (e.clientY < minY || e.clientY >= minY + e.target.clientHeight)) {
                elem.close();
            }
        });
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd} sensors={[this.captureSensorApi(this)]}>
                <div className={"HeaderContainer"}>
                    <div className={"Header"}>
                        <span title={"Sortle sorta sounds similar to turtle :)"}>🐢</span>
                        <span>Sortle</span>
                        <span className={"Icons"}>
                            <span></span>
                            <span className="material-symbols-rounded" onClick={() => { this.openModal(document.querySelector('#help-dialog')); }}>help</span>
                            <span className="material-symbols-rounded" style={{display: 'none'}} onClick={() => { this.openModal(document.querySelector('#stats-dialog')); }}>leaderboard</span>
                        </span>
                    </div>
                </div>
                <dialog id={"help-dialog"}>
                    <span id={"close"} className="material-symbols-rounded" onClick={() => { document.querySelector('#help-dialog').close(); }}>
                        close
                    </span>
                    <h2>How to play</h2>
                    <section>
                        <p>Drag the <span className="material-symbols-rounded">crop_portrait</span> card onto the line below into its correct position.</p>
                        <p>The game ends when {items} <span className="material-symbols-rounded">crop_portrait</span> cards are on the line.</p>
                        <p>Cards award its <span className="material-symbols-rounded">star</span> stars if placed correctly. Get 3 <span className="material-symbols-rounded">star</span> to win.</p>
                    </section>
                    <h3>Colors</h3>
                    <section>
                        <p style={{color: 'var(--blue-light)'}}><span style={{backgroundColor: 'var(--blue)', borderRadius: '4px', padding: '4px 8px'}}>Blue</span> Awaiting your guess</p>
                        <p><span style={{backgroundColor: 'var(--gray)', borderRadius: '4px', padding: '4px 8px'}}>Gray</span> Started on the line, wont show up when sharing results</p>
                        <p style={{color: 'var(--green-light)'}}><span style={{backgroundColor: 'var(--green)', borderRadius: '4px', padding: '4px 8px'}}>Green</span> Correct guess</p>
                        <p style={{color: 'var(--red-light)'}}><span style={{backgroundColor: 'var(--red)', borderRadius: '4px', padding: '4px 8px'}}>Red</span> Incorrect guess</p>
                    </section>
                    <h3>Daily</h3>
                    <section>
                        <p>Come back every day for a new Sortle! Updates daily at midnight ET.</p>
                    </section>
                </dialog>
                <dialog id={"stats-dialog"}>
                    <span id={"close"} className="material-symbols-rounded" onClick={() => { document.querySelector('#stats-dialog').close(); }}>
                        close
                    </span>
                    <h2>Stats</h2>
                    <section>
                        <p>Under construction :)</p>
                    </section>
                </dialog>
                <Droppable droppableId="droppable2">
                    {(provided, snapshot) => (
                        <div
                          className={"GrabZone"}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {this.getNextCard() && Object.keys(this.state.correctMap).length + this.state.items2.length === items &&
                                <Draggable key={this.getNextCard().card_id} draggableId={this.getNextCard().card_id} index={0}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                                false,
                                                this.state.items.length
                                            )}
                                        >
                                            <Card card={this.getNextCard()} correctMap={{}} />
                                        </div>
                                    )}
                                </Draggable>
                            }
                            {!this.getNextCard() && Object.keys(this.state.correctMap).length + this.state.items2.length === items &&
                              <GameEnd id={this.state.id} name={this.state.name} items={this.state.items} correctMap={this.state.correctMap} timeNext={this.state.timeNext} />
                            }
                            {provided.placeholder}
                            {/*{this.state.items2.length > 0 &&*/}
                            {/*  <div className={"DragHint"}>*/}
                            {/*      ↓*/}
                            {/*  </div>*/}
                            {/*}*/}
                        </div>
                    )}
                </Droppable>
                <div className={"BoardContainer"}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                          <div
                            className={"DropZone"}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getLongListStyle(snapshot.isDraggingOver)}
                          >
                              <div className={"Board"}>
                                  <div className={"Legend"}>
                                      <span><span>⤎</span>&nbsp;{this.state.valueType === 'datetime' ? 'Earlier' : 'Less'}</span>
                                      <span>{this.state.valueType === 'datetime' ? 'Later' : 'More'}&nbsp;<span>⤏</span></span>
                                  </div>
                                  <div className={"Line"}></div>
                              </div>
                              {this.state.items.map((item, index) => (
                                <Draggable key={item.card_id} draggableId={item.card_id} index={index} isDragDisabled={this.state.correctMap.hasOwnProperty(item.card_id)}>
                                    {(provided, snapshot) => (
                                      <div
                                        className={"Item"}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                          true,
                                          this.state.items.length
                                        )}
                                      >
                                          <Card card={item} correctMap={this.state.correctMap} />
                                      </div>
                                    )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                          </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        );
    }
}

export default App;
