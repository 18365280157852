import {useEffect, useState} from "react";

function TimeLeft({ time }) {
    const [msLeft, setMsLeft] = useState(time - new Date().getTime());
    const hoursLeft = Math.floor(msLeft / (60 * 60 * 1000));
    const msSansHours = msLeft - (hoursLeft * (60 * 60 * 1000));
    const minsLeft = Math.floor(msSansHours / (60 * 1000));
    const paddedMinsLeft = (minsLeft < 10 ? '0' : '') + minsLeft;
    const msSansHoursMins = msSansHours - (minsLeft * (60 * 1000));
    const secsLeft = Math.floor(msSansHoursMins / 1000);
    const paddedSecsLeft = (secsLeft < 10 ? '0' : '') + secsLeft;
    useEffect(() => {
        setTimeout(() => {
            setMsLeft(time - new Date().getTime());
        }, 90);
    }, [msLeft]);
    return (
        <div>{hoursLeft}:{paddedMinsLeft}:{paddedSecsLeft}</div>
    )
}

export default TimeLeft;
