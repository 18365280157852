import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.isScrollScreen = () => window.innerWidth < 1040;

// combined with hack in App.js to fix centering offset
window.addEventListener("mousemove", (event) => {
  if (!event.isTrusted) {
    return;
  }
  event.preventDefault();
  const items = document.querySelectorAll('[data-rbd-droppable-id="droppable"] > .Item').length;
  const widthTaken = items * 208;
  const widthRemaining = Math.max(window.innerWidth - widthTaken, 0) / 2;
  const customEvent = new MouseEvent("mousemove", {
    clientX: event.clientX - Math.min(104, widthRemaining),
    clientY: event.clientY,
    button: event.button,
  });
  Object.defineProperty(customEvent, 'target', {value: event.target, enumerable: true});
  window.dispatchEvent(customEvent);
}, true);
let startTime = 0;
window.addEventListener("touchmove", (event) => {
  event.stopPropagation();
  // hack: hide "touchmove" from the dnd library so it doesn't try to cancel the drag (make it think user is long press)
  if (new Date().getTime() - startTime <= 125) {
    return;
  }
  if (!event.isTrusted) {
    return;
  }

  const items = document.querySelectorAll('[data-rbd-droppable-id="droppable"] > .Item').length;
  const widthTaken = items * 208;
  const widthRemaining = Math.max(window.innerWidth - widthTaken, 0) / 2;
  const touch = new Touch({
    identifier: 0,
    target: event.touches[0].target,
    clientX: event.touches[0].clientX + Math.min(104, widthRemaining),
    clientY: event.touches[0].clientY,
  });
  const customEvent = new TouchEvent("touchmove", {
    touches: [touch],
  });
  window.dispatchEvent(customEvent);
}, {capture: true});
window.addEventListener("touchstart", (event) => {
  if (!event.isTrusted) {
    return;
  }
  event.stopPropagation();
  const touch = new Touch({
    identifier: 0,
    target: event.touches[0].target,
    clientX: event.touches[0].clientX,
    clientY: event.touches[0].clientY,
  });
  const touchStart = new TouchEvent("touchstart", {
    touches: [touch],
  });
  Object.defineProperty(touchStart, 'target', {value: event.target, enumerable: true});
  window.dispatchEvent(touchStart);
  startTime = new Date().getTime();
}, {capture: true, passive: false});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
