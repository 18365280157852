import ShareButton, {getStars} from "./ShareButton";
import './GameEnd.css';
import TimeLeft from "./TimeLeft";

function GameEnd({ id, name, items, correctMap, timeNext }) {
  const stars = getStars(items, correctMap);
  const className = stars >= 3 ? 'Correct' : 'Incorrect';
  return (
    <div className={"GameEnd"}>
      <h3>Sortled!</h3>
      <span>Next Sortle in <TimeLeft time={timeNext}></TimeLeft></span>
      <div className={"Stars " + className}>
        <span className={"material-symbols-rounded"}>star</span><span>{stars}/5</span>
      </div>
      <ShareButton id={id} name={name} items={items} correctMap={correctMap} />
    </div>
  )
}

export default GameEnd;
